var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "track_content" },
    [
      _c("h2", [_vm._v("物流跟踪")]),
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "track-card",
          attrs: { shadow: "never" },
        },
        [
          _c("p", [
            _c("span", [_vm._v("公司名称：")]),
            _c("span", [_vm._v(_vm._s(_vm.logisticName))]),
          ]),
          _c(
            "p",
            [
              _c("span", [_vm._v("快递单号：")]),
              _c("span", [_vm._v(_vm._s(_vm.logisticNo))]),
              _c(
                "el-button",
                {
                  attrs: { type: "text", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.handleClipboard($event)
                    },
                  },
                },
                [_vm._v(" 复制 ")]
              ),
            ],
            1
          ),
          _c("p", [
            _c("span", [_vm._v("备注：")]),
            _c("span", [_vm._v(_vm._s(_vm.detail?.memo))]),
          ]),
          _vm.detail?.memoImg
            ? _c(
                "div",
                { staticClass: "margin-l20" },
                _vm._l(_vm.detail?.memoImg.split(","), function (value) {
                  return _c("el-image", {
                    key: value,
                    staticStyle: { width: "60px", height: "60px" },
                    attrs: {
                      src: value,
                      fit: "contain",
                      "preview-src-list": _vm.detail?.memoImg.split(","),
                    },
                  })
                }),
                1
              )
            : _vm._e(),
          _c("el-divider"),
          _vm.tracks && _vm.tracks.length > 0
            ? _c(
                "el-timeline",
                _vm._l(_vm.tracks, function (item) {
                  return _c(
                    "el-timeline-item",
                    {
                      key: item.time,
                      attrs: {
                        size: "large",
                        timestamp: item.timestamp,
                        icon: item.icon,
                        color: item.color,
                        placement: "top",
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(item.context))])]
                  )
                }),
                1
              )
            : _c("p", [_vm._v(" 未查询到物流信息 ")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }