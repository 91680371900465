<template>
  <div class="track_content">
    <h2>物流跟踪</h2>
    <el-card
      v-loading="loading"
      shadow="never"
      class="track-card"
    >
      <p>
        <span>公司名称：</span>
        <span>{{ logisticName }}</span>
      </p>
      <p>
        <span>快递单号：</span>
        <span>{{ logisticNo }}</span>
        <el-button
          type="text"
          size="medium"
          @click="handleClipboard($event)"
        >
          复制
        </el-button>
      </p>
      <p>
        <span>备注：</span>
        <span>{{ detail?.memo }}</span>
      </p>
      <div
        v-if="detail?.memoImg"
        class="margin-l20"
      >
        <el-image
          v-for="value in detail?.memoImg.split(',')"
          :key="value"
          style="width: 60px; height: 60px"
          :src="value"
          fit="contain"
          :preview-src-list="detail?.memoImg.split(',')"
        />
      </div>
      <el-divider />
      <el-timeline v-if="tracks && tracks.length > 0">
        <el-timeline-item
          v-for="item in tracks"
          :key="item.time"
          size="large"
          :timestamp="item.timestamp"
          :icon="item.icon"
          :color="item.color"
          placement="top"
        >
          <span>{{ item.context }}</span>
        </el-timeline-item>
      </el-timeline>
      <p v-else>
        未查询到物流信息
      </p>
    </el-card>
  </div>
</template>

<script>
import clipboard from '@/utils/clipboard';

export default {
  name: 'track-detail',
  data() {
    return {
      tracks: [],
      loading: false,
      detail: null,
    };
  },
  computed: {
    logisticCode() {
      return this.$route.query.logisticCode;
    },
    logisticNo() {
      return this.$route.query.logisticNo;
    },
    logisticName() {
      return this.$route.query.logisticName;
    },
  },
  mounted() {
    this.getTrackData();
  },
  methods: {
    async getTrackData() {
      this.loading = true;
      const { code, data } = await this.$axios.post(this.$api.refund.refundTrack, {
        logisticCode: this.logisticCode,
        logisticNo: this.logisticNo,
        orderId: this.$route.query.orderId,
      });
      this.loading = false;
      if (code === 0) {
        this.detail = data;
        this.tracks = data.data.map((item, index) => {
          return {
            item: item.time,
            timestamp: `【${item.status}】 ${item.time}`,
            context: item.context,
            icon: index === 0 ? 'el-icon-success' : 'el-icon-more',
            color: index === 0 ? '#409EFF' : 'el-icon-more',
          };
        });
      }
    },
    handleClipboard(event) {
      clipboard(this.logisticNo, event);
    },
  },
};
</script>

<style lang="scss" scoped>
.track_content {
  background: #fff;
  padding: 10px;
}
.track-card {
  margin-top: 20px;
  p {
    font-size: 16px;
    line-height: 30px;
    span {
      padding: 16px;
    }
  }
}
</style>
